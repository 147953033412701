<template>
<v-container>
    <v-row>
            <v-col>
            <v-card max-width="160" elevation="2" outlined shaped class = "mx-2">

              <v-card-text> 
                             <v-form>
                                        <v-tooltip top>
                                                  <v-text-field v-model = "person_name" label = "who" slot="activator"/> 
                                                  <span> ... </span>
                                        </v-tooltip>
                                        
                             </v-form>
              </v-card-text>

                <v-menu
                  v-model = "menu"
                  :close-on-content-click = "false"
                  :nudge-right = "40"
                  transition = "scale-transition"
                  offset-y
                  min-width = "290px"
                >
                          <template v-slot:activator="{ on, attrs }">
                                                                      <v-text-field
                                                                        v-model      = "date"
                                                                        prepend-icon = "mdi-calendar"
                                                                        readonly
                                                                        v-bind       = "attrs"
                                                                        v-on         = "on"
                                                                        class        = "mx-2"
                                                                        
                                                                      ></v-text-field>
                          </template>

                          <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">
                                                        <v-date-picker v-model = "date" 
                                                                       @input = "menu = false" 
                                                                       v-bind = "attrs"
                                                                       v-on   = "on"
                                                         > 
                                                        </v-date-picker> <br>
                                          </template>            
                                          <span> choose birthdate </span>  
                          </v-tooltip>

                          <span> date picker </span>

                </v-menu>

                <v-card-actions>
                                   <v-spacer> </v-spacer>
                                     
                                   <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                                  <v-btn 
                                                                         x-small width = "30" 
                                                                         color  = "info" 
                                                                         @click = "save_who" 
                                                                   > + 
                                                                   </v-btn>
                                                    </template>
                                                    <span> add to list </span>
                                   </v-tooltip>

                </v-card-actions>

            </v-card>    
            </v-col> <br> 

    </v-row>
    <br>

    <!--------------------------------------------------------------------------------->
    <v-row>
    <v-col>
              <ul v-if = whos.length>
              <li :key = "who.id" v-for= "who in whos"> 

                  <v-icon v-if = "who.kk === 100 " style = "color:red"  > mdi-account-circle  </v-icon>
                  <v-icon v-else                   style = "color:blue" > mdi-circle-small    </v-icon>


                  <v-tooltip top>
                  <template v-slot:activator="{ on }">
                                                                   <v-btn 
                                                                          x-small 
                                                                          width = "30" 
                                                                          v-if = " who.element == 'earth' " 
                                                                          color = "green"                      
                                                                          @click = "pick_Top_User(who)"> {{ get_short_name(who.id) }}    
                                                                  </v-btn> 
                  </template>
                  <span> move this person to the Top </span>
                  </v-tooltip>


                  <v-tooltip top>
                  <template v-slot:activator="{ on }">
                                                                   <v-btn 
                                                                          x-small 
                                                                          width = "30" 
                                                                          v-if = " who.element == 'air' " 
                                                                          color = "white"                      
                                                                          class = "blue--text"
                                                                          @click = "pick_Top_User(who)"> {{ get_short_name(who.id) }}    
                                                                  </v-btn> 
                  </template>
                  <span> move this person to the Top </span>
                  </v-tooltip>


                  <v-tooltip top>
                  <template v-slot:activator="{ on }">
                                                                   <v-btn 
                                                                          x-small 
                                                                          width = "30" 
                                                                          v-if = " who.element == 'water' " 
                                                                          color = "blue"                      
                                                                          @click = "pick_Top_User(who)"> {{ get_short_name(who.id) }}    
                                                                  </v-btn> 
                  </template>
                  <span> move this person to the Top </span>
                  </v-tooltip>


                  <v-tooltip top>
                  <template v-slot:activator="{ on }">
                                                                   <v-btn 
                                                                          x-small 
                                                                          width = "30" 
                                                                          v-if = " who.element == 'fire' " 
                                                                          color = "red"                      
                                                                          @click = "pick_Top_User(who)"> {{ get_short_name(who.id) }}    
                                                                  </v-btn> 
                  </template>
                  <span> move this person to the Top </span>
                  </v-tooltip>

                  {{ who.bday }} 

                                   <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                                  <v-btn 
                                                                         x-small 
                                                                         color  = "red" 
                                                                         @click = "remove_who(who)" 
                                                                   > x 
                                                                   </v-btn>
                                                    </template>
                                                    <span> delete this person from List </span>
                                   </v-tooltip>


                    <br>
                       
              </li>
              </ul>
              
    </v-col>

    <!---
    <Birth_Grid > </Birth_Grid>
    --->

    </v-row>


            <!--------------------------------------------------------------->
            <v-simple-table 
              dense
              >
              <template >

                <thead>
                      <tr>
                                                            <th style = "text-align:left">   <i> who   </i> </th>
                                                            <th style = "text-align:center"> <i> z     </i> </th>
                                                            <th style = "text-align:center"> <i> yr    </i> </th>
                                                            <th style = "text-align:center"> <i> ❤️    </i> </th>
                                                            <th style = "text-align:center"> <i> ☯️    </i> </th>
                      </tr> 
              </thead>

              <tbody>
                        <tr v-for = "who, kk in whos">
                                                            <td style = "text-align:left">   {{ get_short_name(who.id) }}      </td>
                                                            <td style = "text-align:center"> {{ get_Sign_Icon(who.sign)   }} </td>
                                                            <td style = "text-align:center"> {{ get_Animal_Icon(who.year_Of)   }} </td>

                                                            <td v-if = "kk === 0" >                                                   </td>
                                                            <td v-else  style = "text-align:center"> {{ get_nbr_hearts(who.sign)   }} </td>

                                                            <td v-if = "kk === 0" >                                                   </td>
                                                            <td v-else  style = "text-align:center"> {{ get_nbr_stars(who.year_Of)   }} </td>
                        </tr>
                </tbody>

                </template>
            </v-simple-table>
 


</v-container>
</template>

<!------------------------------------------------------------>
<script>

  const STORAGE_KEY = '1442495937' ;

  import { getters }        from '../mixins/getters'        ;
  import { getter_Chinese } from '../mixins/getter_Chinese' ;
  import { calc_Hearts }    from '../mixins/calc_Hearts' ;
  import { calc_Stars }     from '../mixins/calc_Stars' ;

  export default {

    data: () => ({
                    date: new Date().toISOString().substr(0, 10),
                    menu: false,
                    current_Who: null,
                    person_name: '',

                    whos: [],
                    
                    who: [
                          {
                            kk: null, name: '', b_Date: '', sign: '', year_Of: '' 
                          }
                         ],

                    z_date: '',
                    nbr_Hearts: 0,

                  }),
  //_________________________________________________________________________________________________________________________________
  created() {
     let whos = [] ;
     this.whos = JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '[]' ) ;

    //  console.log("this whos") ;
    //  console.log(this.whos) ;
  },

  //_________________________________________________________________________________________________________________________________

  mixins: [getters, calc_Hearts, calc_Stars, getter_Chinese],

  //_________________________________________________________________________________________________________________________________

methods: {

  get_short_name(who_long) {
                              let who_short = who_long.substring( 0, 6 ) ;  
                           return who_short ;
  },
  
//_____________________________________________________________________
  save_who () {

                                                                    let x_Sign_3      = '' ;
                                                                    let x_Sign_Long   = '' ;

                                                                    let x_nbr_hearts = 0 ;

                                                                    let x_Element     = '' ;
                                                                    let x_year_Of     = '' ;
                                                                    let sign_baseLine = '' ;
                                                                    let cc = 0 ;
                                                                    //  let animal_Icon = '' ;

                                                                    let x_Date = '' ;
                                                                        x_Date = this.date ;
                                             x_Sign_3 = this.get_Sign_3(x_Date) ;      
            x_Sign_Long = this.get_Sign_Long(x_Sign_3) ;

            x_Element   = this.get_Element(x_Sign_3) ;

                                                             x_year_Of = this.get_Ch_Sign(x_Date) ;
                                           let x_year_Of_3 = x_year_Of.substring(0,3) ;
            // animal_Icon = this.get_Animal_Icon(x_year_Of_3) ;

                        if ( !this.whos || this.whos.length <= 1 ) { let no_op = 0 ; }
                        else                             {
                                                         sign_baseLine = this.whos[0].sign ;
                                          let sign_1_3 = sign_baseLine.substring(2,5) ;
                                          let sign_2_3 = x_Sign_3 ;

                                               x_nbr_hearts = this.get_nbr_hearts( sign_1_3, sign_2_3 ) ;
                        }


                        let kx = this.whos.length + 100 ;

                                                                                      this.whos.push({ kk:      kx,
                                                                                                       id:      this.person_name, 
                                                                                                       bday:    this.date, 
                                                                                                       sign:    x_Sign_3,
                                                                                                       element: x_Element,
                                                                                                       year_Of: x_year_Of_3
                                                                                                     }) ;

                                      sessionStorage.setItem( STORAGE_KEY, JSON.stringify(this.whos) ) ;

                      //  let x_Icon = '' ; 

                      //                                                       let total_nbr_whos = this.whos.length ;
                      //                                      for (  cc = 0 ; cc < total_nbr_whos ; cc++) {   
     

                      //                                      }  
    },

     get_Animal_Icon(xx) {                                                             let x_Animal_Icon = '' ;
                                                                  switch(xx) {
                                                                                        case 'Rat': x_Animal_Icon = '🐀'  ;  break ;    
                                                                                        case 'Ox' : x_Animal_Icon = '🐮'  ;  break ;    
                                                                                        case 'Tig': x_Animal_Icon = '🐯'  ;  break ;    
                                                                                        case 'Rab': x_Animal_Icon = '🐰'  ;  break ;    
                                                                                        case 'Dra': x_Animal_Icon = '🐲'  ;  break ;    
                                                                                        case 'Sna': x_Animal_Icon = '🐍'  ;  break ;    
                                                                                        case 'Hor': x_Animal_Icon = '🐴'  ;  break ;    
                                                                                        case 'Goa': x_Animal_Icon = '🐐'  ;  break ;    
                                                                                        case 'Mon': x_Animal_Icon = '🐵'  ;  break ;    
                                                                                        case 'Roo': x_Animal_Icon = '🐔'  ;  break ;    
                                                                                        case 'Dog': x_Animal_Icon = '🐶'  ;  break ;    
                                                                                        case 'Pig': x_Animal_Icon = '🐷'  ;  break ;    
                                                                                        default   : x_Animal_Icon = ''   
                                                                                       }
                                                                                       return x_Animal_Icon ;


     },

    get_Sign_Icon(x_Sign_3) {
                                            let x_Icon = '' ;
           switch (x_Sign_3) {
                                case 'Ari'  : x_Icon = '♈' ; break ;
                                case 'Tau'  : x_Icon = '♉' ; break ;
                                case 'Gem'  : x_Icon = '♊' ; break ;
                                case 'Can'  : x_Icon = '♋' ; break ;
                                case 'Leo'  : x_Icon = '♌' ; break ;
                                case 'Vir'  : x_Icon = '♍' ; break ;
                                case 'Lib'  : x_Icon = '♎' ; break ;
                                case 'Sco'  : x_Icon = '♏' ; break ;
                                case 'Sag'  : x_Icon = '♐' ; break ;
                                case 'Cap'  : x_Icon = '♑' ; break ;
                                case 'Aqu'  : x_Icon = '♒' ; break ;
                                case 'Pis'  : x_Icon = '♓' ; break ;
                }               
                                          return x_Icon ;                      

     },
    //_________________________________________________________________________
    remove_who(who){
      this.whos.splice(this.whos.indexOf(who), 1 );

      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.whos) ) ;
    }, 


//_________________________________________________________________________
    pick_Top_User(who) {

      let xx_Index   = this.whos.indexOf(who) ; 

       this.whos[xx_Index].kk = 100 ; 

      let top_Person = this.whos[xx_Index] ;

      let xx_kk = this.who.kk ;

                       this.whos.splice(xx_Index, 1 );

                       this.whos.unshift( top_Person ) ;

      for ( let ix = 0 ; ix < this.whos.length ; ix ++) {
                                                           this.whos[ix].kk = ix + 100 ;
                                                        }                

       sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.whos) ) ;
    },

  }              
  }
</script>

<!-------------------------------------------------------------------->
<style scoped>

ul {
   list-style-type: none;
}

</style>