var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-2",attrs:{"max-width":"160","elevation":"2","outlined":"","shaped":""}},[_c('v-card-text',[_c('v-form',[_c('v-tooltip',{attrs:{"top":""}},[_c('v-text-field',{attrs:{"slot":"activator","label":"who"},slot:"activator",model:{value:(_vm.person_name),callback:function ($$v) {_vm.person_name=$$v},expression:"person_name"}}),_c('span',[_vm._v(" ... ")])],1)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-date-picker',_vm._g(_vm._b({on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-date-picker',attrs,false),on)),_vm._v(" "),_c('br')]}}])},[_c('span',[_vm._v(" choose birthdate ")])]),_c('span',[_vm._v(" date picker ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"x-small":"","width":"30","color":"info"},on:{"click":_vm.save_who}},[_vm._v(" + ")])]}}])},[_c('span',[_vm._v(" add to list ")])])],1)],1)],1),_vm._v(" "),_c('br')],1),_c('br'),_c('v-row',[_c('v-col',[(_vm.whos.length)?_c('ul',_vm._l((_vm.whos),function(who){return _c('li',{key:who.id},[(who.kk === 100 )?_c('v-icon',{staticStyle:{"color":"red"}},[_vm._v(" mdi-account-circle ")]):_c('v-icon',{staticStyle:{"color":"blue"}},[_vm._v(" mdi-circle-small ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [( who.element == 'earth' )?_c('v-btn',{attrs:{"x-small":"","width":"30","color":"green"},on:{"click":function($event){return _vm.pick_Top_User(who)}}},[_vm._v(" "+_vm._s(_vm.get_short_name(who.id))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" move this person to the Top ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [( who.element == 'air' )?_c('v-btn',{staticClass:"blue--text",attrs:{"x-small":"","width":"30","color":"white"},on:{"click":function($event){return _vm.pick_Top_User(who)}}},[_vm._v(" "+_vm._s(_vm.get_short_name(who.id))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" move this person to the Top ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [( who.element == 'water' )?_c('v-btn',{attrs:{"x-small":"","width":"30","color":"blue"},on:{"click":function($event){return _vm.pick_Top_User(who)}}},[_vm._v(" "+_vm._s(_vm.get_short_name(who.id))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" move this person to the Top ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [( who.element == 'fire' )?_c('v-btn',{attrs:{"x-small":"","width":"30","color":"red"},on:{"click":function($event){return _vm.pick_Top_User(who)}}},[_vm._v(" "+_vm._s(_vm.get_short_name(who.id))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" move this person to the Top ")])]),_vm._v(" "+_vm._s(who.bday)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"x-small":"","color":"red"},on:{"click":function($event){return _vm.remove_who(who)}}},[_vm._v(" x ")])]}}],null,true)},[_c('span',[_vm._v(" delete this person from List ")])]),_c('br')],1)}),0):_vm._e()])],1),_c('v-simple-table',{attrs:{"dense":""}},[[_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"left"}},[_c('i',[_vm._v(" who ")])]),_c('th',{staticStyle:{"text-align":"center"}},[_c('i',[_vm._v(" z ")])]),_c('th',{staticStyle:{"text-align":"center"}},[_c('i',[_vm._v(" yr ")])]),_c('th',{staticStyle:{"text-align":"center"}},[_c('i',[_vm._v(" ❤️ ")])]),_c('th',{staticStyle:{"text-align":"center"}},[_c('i',[_vm._v(" ☯️ ")])])])]),_c('tbody',_vm._l((_vm.whos),function(who,kk){return _c('tr',[_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm.get_short_name(who.id))+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.get_Sign_Icon(who.sign))+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.get_Animal_Icon(who.year_Of))+" ")]),(kk === 0)?_c('td'):_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.get_nbr_hearts(who.sign))+" ")]),(kk === 0)?_c('td'):_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.get_nbr_stars(who.year_Of))+" ")])])}),0)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }